<script>
export default {
  name: "BaseTile",
  props: {
    tileIndex: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
/**
 * Sass variables
 */
$picto_dimension: calc((16vw + 9vh) / 2);
$picto_border: 1vw;
$picto_gutter: 2vw;
$picto_inner: calc(((16vw + 9vh) / 2) - 1vw - 2vw);

/**
 * Styles
 */
.tile {
  &.v-card {
    display: flex;
    align-items: stretch;
    position: relative;
    width: 100%;
    min-height: 100%;
    margin-bottom: var(--grid-gutter);
    background-color: transparent;
    border-radius: var(--border-radius-lg);
    box-shadow: none;

    @media (max-width: $wx-max-width-phone-vertical) {
      min-width: 180px;
    }
    @media (min-width: $wx-min-width-phone-vertical) {
      min-width: calc((50% - var(--grid-gutter)) / 2);
    }
    @media ($wx-md-min) {
      min-width: 130px;
    }

    &::before,
    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
    &::before {
      opacity: 1;
    }
    &::after {
      opacity: 0;
      transition: opacity 0.3s linear;
      background: var(--color-base-theme);
      pointer-events: none;
    }
  }

  .tile-inner {
    &,
    &.v-btn,
    &.v-btn ::v-deep .v-btn__content {
      display: flex;
      justify-content: flex-start;
    }
  }
  &:not(.disconnected, .status-pictogram) {
    .tile-inner {
      border-bottom-color: White;
    }
  }

  &.sample-waiting {
    background-color: var(--color-primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.sample-pending {
    background-color: var(--color-secondary);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /**
   * Status Pictogram Tiles
   * Display target with circle shape
   * as pictogram for status indicator
   */
  &:not(.status-pictogram) {
    .pictogram {
      display: none;
    }
  }
  &.status-pictogram {
    .tile-inner {
      height: auto;
      border-bottom-color: var(--color-tile-background);

      // fix pictogram hiding long OEE% values on SM breakpoint
      .primary-value,
      .secondary-title,
      .secondary-value {
        z-index: 1;
        text-shadow: 0 0 2px 5px var(--color-base-background);
      }
      .status-details {
        &,
        & .v-icon {
          // color: var(--color-text-subtle-theme);
        }
        dl {
          border-color: var(--color-text-subtle-theme);
        }
        .pictogram {
          z-index: 0;
        }
      }
    }
    // Red pictogram = bad
    &.error-state {
      .tile-inner {
        border-bottom-color: var(--color-error);

        .pictogram {
          &::before {
            border-color: var(--color-error);
          }
          &::after {
            background-color: var(--color-error-alpha);
          }
        }
      }
    }
    // Yellow pictogram = average
    &.warning-state {
      .tile-inner {
        border-bottom-color: var(--color-warning);
        .pictogram {
          &::before {
            border-color: var(--color-warning);
          }
          &::after {
            background-color: var(--color-warning-alpha);
          }
        }
      }
    }
    // Green pictogram = good
    &.success-state {
      .tile-inner {
        border-bottom-color: var(--color-success);
        .pictogram {
          &::before {
            border-color: var(--color-success);
          }
          &::after {
            background-color: var(--color-success-alpha);
          }
        }
      }
    }
    // Gray pictogram = no target
    &.default-state {
      .tile-inner {
        border-bottom-color: var(--color-secondary);
        .pictogram {
          &::before {
            border-color: var(--color-secondary);
          }
          &::after {
            background-color: var(--color-secondary-alpha);
          }
        }
      }
    }
  }

  /**
   * Tiles content wrapper
   */
  .tile-inner {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%; // force height to .tile-slider
    padding: var(--box-padding-dashboard) 0 var(--box-padding-dashboard) var(--box-padding-dashboard) !important;
    background: var(--color-base-background);
    border-radius: var(--border-radius-lg) !important;
    border-bottom: 3px solid var(--color-secondary);

    & > * {
      transition: none;
    }
    // text-truncate
    & .primary-title {
      display: inline-block;
      font-size: 4.5vw;
      width: calc(100% - 40px);
      min-height: 1.1em; // prevent vertical cutoff
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      @media ($wx-sm-min) {
        font-size: 2.5vw;
      }
      @media ($wx-md-min) {
        font-size: calc(0.625vw + 0.625vh);
      }
    }

    h2.primary-value,
    h2.secondary-value {
      font-size: 5.75vw;
      line-height: 1.16666666;
      font-weight: 700; /* Bold */

      @media ($wx-sm-min) {
        font-size: 3.75vw;
      }

      @media ($wx-md-min) {
        font-size: calc(1.25vw + 1.25vh);
      }
    }

    p.value-sub-text {
      &__unit,
      &__product-note {
        margin-top: -0.75em;
        margin-bottom: 0;
        font-size: 3vw;

        @media ($wx-sm-min) {
          font-size: 2.25vw;
        }

        @media ($wx-md-min) {
          margin-top: -1.2em;
          font-size: calc(0.5vw + 0.5vh);
        }
      }
      &__unit.null {
        font-style: italic;
      }
      &__product-note {
        font-size: var(--font-size-sm);
        color: var(--color-text-subtle-theme);
      }
    }

    // .tile-inner > div.status-details
    .status-details {
      display: flex;
      flex-grow: 1;
      align-items: flex-end;
      font-size: 3vw;
      margin-top: 0.5em;

      @media ($wx-sm-min) {
        font-size: 2.25vw;
      }

      @media ($wx-md-min) {
        font-size: calc(0.75vh + 0.75vw);
      }

      & .v-icon {
        font-size: 2.5vw;
        color: var(--color-text-theme);

        @media ($wx-sm-min) {
          font-size: 2.25vw;
        }

        @media ($wx-md-min) {
          font-size: calc(0.6875vw + 0.6875vh);
        }
      }

      dl {
        // fix absolute positioned .pictogram
        position: relative;
        z-index: 1;
        padding-left: 0.3rem;
        border-left: 2px solid var(--color-text-theme);
      }
    }

    // .status-details > div.pictogram
    .pictogram {
      //flex-basis: 0;
      position: absolute;
      right: calc(0px - (12vw / 5));
      bottom: calc(0px - (12vw / 5));
      width: $picto_dimension;
      height: $picto_dimension;

      &::before,
      &::after {
        content: "";
        display: inline-block;
        position: absolute;
        top: 0;
        right: 0;
        height: 0;
        border-radius: 50%;
        border-width: 0;
        transition: var(--smooth-transition);
      }
      &::before {
        width: $picto_dimension;
        height: $picto_dimension;
        border: $picto_border solid var(--color-tile-background);
      }
      &::after {
        width: $picto_inner;
        height: $picto_inner;
        top: 1.5vw;
        left: 1.5vw;
        background-color: var(--color-tile-background);
        transition: var(--smooth-transition);
      }
    }
  }

  /**
   * TileInProgressDowntime.vue
   * [tileColor ? .downtime : .uptime]
   * [.disabled: deviceLatestAlert]
   */

  // // .tile.in-progress.[tileColor]
  &.in-progress {
    &.uptime {
      &::before {
        content: none;
      }
    }

    &.uptime-disabled {
      &::before {
        content: none;
      }
      &::after {
        opacity: 0.7;
      }
    }

    .tile-inner {
      &.v-btn {
        width: 100%;

        ::v-deep .v-btn__content {
          flex-direction: column;
          align-items: stretch;
          position: relative;
          width: 100%;
          height: 100%;
          text-align: left;
        }
      }
    }

    // Red = .tile.in-progress.downtime
    &.downtime {
      .tile-inner {
        color: White;
        background: var(--color-error);

        .primary-title,
        .status-details {
          font-weight: 500;
        }
        .primary-title,
        .primary-value {
          text-shadow: var(--white-text-shadow-small-level);
        }
        .status-details {
          color: White;

          dl {
            border-color: White;
          }
        }
      }
    }
    &.downtime-disabled {
      &::after {
        opacity: 0.7;
      }
      .tile-inner {
        color: White;
        background: var(--color-error);

        .primary-title,
        .status-details {
          font-weight: 500;
        }
        .primary-title,
        .primary-value {
          text-shadow: var(--white-text-shadow-small-level);
        }
        .status-details {
          color: White;

          dl {
            border-color: White;
          }
        }
      }
    }

    // .tile.in-progress.[deviceLatestAlert]
    &.disabled {
      &::before {
        opacity: 0;
      }
      &::after {
        opacity: 0.7;
      }

      .tile-inner {
        border-bottom-color: var(--color-border-theme);

        .primary-value {
          color: var(--color-text-disabled-theme);
        }
      }
    }
  }

  // TileDisconnected.vue
  &.tile.disconnected {
    // <v-btn.tile-inner>
    .tile-inner {
      width: 100%;
      height: 100%; // force height to .tile-slider
      align-items: flex-start;
      letter-spacing: normal;
      color: White;
      background: var(--color-warning);
      border-bottom-color: White;

      ::v-deep .v-btn__content {
        flex-flow: column;
        align-items: flex-start;
      }

      &:hover::before,
      &:focus::before {
        opacity: 0.15;
      }

      .primary-title {
        font-weight: 500;
      }
      .primary-title,
      .primary-value {
        text-shadow: var(--white-text-shadow-small-level);
      }
    }
  }
}

// Tiles texts
.primary-title,
.secondary-title {
  margin-bottom: 0.2em;
  font-weight: normal;
}
.primary-title {
  .v-icon {
    margin-top: -0.15rem;
    color: var(--color-text-subtle-theme);
  }
}
.primary-value {
  margin-bottom: 0.5em;
}

/** ---------------
 * Responsive styles
 */
.tile.v-card {
  // Phones+Tablets
  @media ($wx-isMobile) {
    .tile-inner {
      min-height: 10em; // match height of `tileTotalDowntime.vue`
    }
  }
  @media ($wx-sm-min) {
    .tile-inner {
      .status-details {
        dl {
          padding-left: 0.5rem;
        }
      }
    }
    &:not(.responsive) {
      width: calc((100% - var(--grid-gutter) * 2) / 3);
    }
  }
  // Desktop
  @media ($wx-isNotMobile) {
    flex-shrink: 0;
    margin-bottom: 0;
    margin-right: calc(var(--grid-gutter) / 2);
    &:last-child {
      margin-right: 0;
    }
    &:not(.responsive) {
      width: calc((100% - (var(--grid-gutter) / 2) * 4) / 5);
    }
    .tile-inner {
      min-height: 160px;
    }
  }
  @media ($wx-lg-min) {
    .tile-inner {
      min-height: 180px;
      border-bottom-width: 4px;
    }
  }
  @media ($wx-xl-min) {
    .tile-inner {
      min-height: 220px;
      border-bottom-width: 5px;
    }
    .secondary-title {
      margin-bottom: 4px;
    }
    .secondary-value {
      font-weight: 700;
    }
  }

  /**
   * TileProductLastSamplingAverageWeight.vue
   * [tileColor ? .bad : .good]
   * [.disabled: deviceLatestAlert]
   */

  // // .tile.in-progress.[tileColor]
  &.sampling {
    .tile-inner {
      &.v-btn {
        width: 100%;

        ::v-deep .v-btn__content {
          flex-direction: column;
          align-items: stretch;
          position: relative;
          height: 100%;
          text-align: left;
        }
      }
    }

    // Red = .tile.sampling.bad
    &.bad {
      .tile-inner {
        color: White;
        background: var(--color-error);

        .primary-title,
        .status-details {
          font-weight: 500;
        }
        .primary-title,
        .primary-value {
          text-shadow: var(--white-text-shadow-small-level);
        }
        .status-details {
          color: White;

          dl {
            border-color: White;
          }
        }
      }
    }

    // Green = .tile.sampling.good
    &.good {
      .tile-inner {
        color: White;
        background: var(--color-success);

        .primary-title,
        .status-details {
          font-weight: 500;
        }
        .primary-title,
        .primary-value {
          text-shadow: var(--white-text-shadow-small-level);
        }
        .status-details {
          color: White;

          dl {
            border-color: White;
          }
        }
      }
    }

    // .tile.sampling.[deviceLatestAlert]
    &.disabled {
      &::before {
        opacity: 0;
      }
      &::after {
        opacity: 0.7;
      }

      .tile-inner {
        border-bottom-color: var(--color-border-theme);

        .primary-value {
          color: var(--color-text-disabled-theme);
        }
      }
    }
  }
}

.unfocused {
  opacity: var(--opacity-disabled-effect);
}
</style>
